.test{
  background-color: aqua;
}

.min-height{
  min-height: 90vh;
}

p{
  font-size: 20px;
}

#home, #about-us, #packages, #contact-us{
  background-image: url('./assets/images/Background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  
}

.paddings {
  padding-top: 2vh;
  padding-bottom: 10vh;
}

.margin-col{
  margin-top: 5vh;
}