.card-text2 {
  font-size: 2.3rem;
  font-weight: bold;
}

.card {
  border-radius: 10px;
  margin-top: 2vh;
  margin-bottom: 2vh;

  transition: 500ms;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);

}

.card:hover {
  transition: 500ms;
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}